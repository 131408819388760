import React, { useEffect, useRef, useState } from 'react'
import TooltipRC from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const Tooltip = ({ children, tooltipContent, className, overlayWidth = 400, arrowPosition = -20, trigger = ['hover'], placement = "topRight", isShow=true}) => {
    const [isMounted, setIsMounted] = useState(isShow);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        isMounted && <TooltipRC
            placement={placement}
            trigger={trigger}
            overlayClassName='bg-gray_800'
            overlayStyle={{
                maxWidth: overlayWidth || 200,
                opacity: 1,
                borderRadius: 4,
                background: 'unset'

            }}
            overlayInnerStyle={{
                background: '#1F1F37',
                padding: '8px 12px',
                fontSize: 14,
                lineHeight: '20px',
                color: '#fff',
                border: 'unset',
                marginLeft: 28,
                marginRight: arrowPosition,
            }}
            overlay={tooltipContent}
        >
            {children[0] ?? children}
        </TooltipRC >

    )
}

export default Tooltip